<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Device history records</h2>
      </div>
    </div>
    <div>
      <div class="row justify-content-md-center mb-3">
        <div class="col-1"><label for="filter">Filter</label></div>
        <div class="col-4">
          <b-form-input v-model="filter"
                  type="text"
                  id="filter"
                  placeholder="e.g. CFR, DMU"></b-form-input>
        </div>
        <div class="col-5"></div>
      </div>
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table  :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :striped="true"
                    :hover="true"
                    :busy.sync="isBusy"
                    :items="fetchReports"
                    :fields="reportFields"
                    :outlined="true"
                    :small="true"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    ref="device-table">
            <template slot="selected" slot-scope="data" label="selected">
                <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected">
                  </b-form-checkbox>
              </template>
            <template slot="index" slot-scope="data" label="test">
              {{data.index}}
            </template>
            <template slot="production_date" slot-scope="data" label="test">
              {{data.item.production_date | formatDate}}
            </template>
            <template slot="batch_id" slot-scope="data" label="result">
              {{data.item.batch_id || "Not assigned"}}
            </template>
            <template slot="released_by" slot-scope="data" label="result">
              {{data.item.username}}
            </template>
            <template slot="status" slot-scope="data" label="result">
              {{data.item.result}}
            </template>
            <template slot="download_certificate" slot-scope="data" label="certificate">
              <a :href="'report/'+data.item.id+'/certificate'">Certificate</a>
            </template>
            <template slot="report" slot-scope="data" label="report">
              <a v-if="data.item.released_by" href="#" @click.prevent.stop="downloadReport(data.item.id, 'report', data.item.end_serial)"><octicon name="cloud-download"></octicon></a>
            </template>
            <template slot="certificate" slot-scope="data" label="report">
              <a  v-if="data.item.released_by" href="#" @click.prevent.stop="downloadReport(data.item.id, 'certificate', data.item.end_serial)"><octicon name="cloud-download"></octicon></a>
            </template>
            <template slot="documents" slot-scope="data" label="report">
              <span>
                <a href="#" @click.prevent.stop v-b-modal="'uploadBox'+data.item.id"><octicon name="repo-push"></octicon><span v-if="data.item.__meta__.documents_count > 0"> ({{ data.item.__meta__.documents_count }})</span></a>
                  <!-- Modal Component -->
                <b-modal @ok="uploadDocument(data.item.id)" :id="'uploadBox'+data.item.id" title="Select document to upload">
                    <div class="mb-3">
                      Choose the documents you want to add to {{data.item.end_serial}}
                    </div>
                    <b-form-file multiple v-model="fileList" ref="fileinput" placeholder="Choose a file..."></b-form-file>
  <div class="mt-3">Selected file(s): {{fileList && cutString(fileList.map(f=>f.name).join(', '))}}</div>
                </b-modal>
              </span>
            </template>
            <template slot="approval" slot-scope="data" label="released">
              <template>
                <a v-if="!data.item.released_by && data.item.result != 'Pass'" v-b-modal="'releaseDevice'+data.item.id" href="#" @click.prevent.stop>Release device</a>
                <a v-else-if="!data.item.released_by" href="#" @click.prevent.stop="releaseDevice(data.item.id)">Release device</a>
              </template>
              <template v-if="data.item.released_by">Released</template>
              <b-modal ok-title="Yes" ok-variant="danger" cancel-title="No" @ok="releaseDevice(data.item.id)" :id="'releaseDevice'+data.item.id" title="Are you sure?">
                <div class="mb-3">
                  This device has not passed the END test!
                  Are you absolutely sure you want to continue?
                </div>

              </b-modal>
            </template>
            <template slot="show_details" slot-scope="row">
              <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
              <a href="#" @click.stop="row.toggleDetails">
                <octicon :name="'triangle-' + (row.detailsShowing ? 'up' : 'down')"></octicon>
              </a>
            </template>
            <template slot="row-details" slot-scope="row">
              <template v-if="row.item.__meta__.documents_count > 0" >
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-left"><b>Documents for this device:</b></b-col>
                </b-row>
                <document-list-table :device_id="row.item.id"></document-list-table>
              </template>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-left"><b>Reports for this device:</b></b-col>
              </b-row>
              <report-list-table :device_id="row.item.id"></report-list-table>
            </template>
          </b-table>
        </div>
      </div>
      <div v-if="(hasRole('administrator') || hasRole('support') || hasRole('productionOwner'))" class="row justify-content-md-center">
        <div class="col-2"><label for="batch_id">With selected devices do</label></div>
        <div class="col-4">
          <b-form-input v-if="hasRole('administrator') || hasRole('support')" v-model="batch_id"
                  type="text"
                  id="batch_id"
                  placeholder="e.g. DMU20180912"></b-form-input>
        </div>
        <div class="col-4">
          <b-button-group>
            <b-button v-if="hasRole('administrator') || hasRole('support')" :disabled="!items_selected" @click.prevent.stop="assignBatchId"><octicon name="file-symlink-directory"></octicon> Assign to batch</b-button>
            <b-button v-if="hasRole('administrator') || hasRole('productionOwner')" :disabled="!items_selected" @click.prevent.stop="releaseDevice(selected_items)"><octicon name="checklist"></octicon> Release devices</b-button>
            <b-button @click.prevent.stop v-b-modal="'uploadMultiple'"><octicon name="repo-force-push"></octicon> Batch upload reports</b-button>
          </b-button-group>
        </div>
        <b-modal @ok="uploadDocument('multiple')" id="uploadMultiple" title="Select documents to upload">
          <div class="mb-3">
            Choose the documents you want to upload
          </div>
          <b-form-file multiple v-model="fileList" ref="fileinput" placeholder="Choose a file..."></b-form-file>
          <div class="mt-3">Selected file(s): {{fileList && cutString(fileList.map(f=>f.name).join(', '))}}</div>
        </b-modal>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-6 mt-3">
          <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import moment from 'moment'
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'
import { mapGetters } from 'vuex'
import reportListTable from './ReportListTable'
import documentListTable from './DocumentListTable'

Vue.use(BootstrapVue)

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD hh:mm')
  }
})
export default {
  name: 'report-list',
  components: {
    Octicon,
    reportListTable,
    documentListTable
  },
  data () {
    return {
      sortBy: 'id',
      sortDesc: true,
      isBusy: false,
      graphLoaded: false,
      devices: [],
      batch_id: null,
      fileList: [],
      currentPage: 1,
      perPage: 12,
      filter: "",
      totalRows: 0,
      reportFields:
      [
        { key: 'selected', label: '' },
        { key: 'id', label: '#' },
        { key: 'end_serial', sortable: true },
        { key: 'last_operation', sortable: true },
        { key: 'revision', sortable: true },
        { key: 'production_date', sortable: true },
        { key: 'version', sortable: true },
        { key: 'batch_name', sortable: true },
        { key: 'status', sortable: true },
        { key: 'released_by', sortable: true },
        { key: 'company', label: 'Produced by', sortable: true },
        // 'report',
        // 'certificate',
        'documents'
        // <% if (me.role == "manager") { %>
        // 'approval'
        // <% } %>
        // <% if (me.role == "support" || me.role == "admin") { %>
        // 'download_certificate',
        // 'download_report'
        // <% } %>
      ]
    }
  },
  computed: {
    items_selected: function () {
      return this.devices.some(r => r.selected)
    },
    selected_items: function () {
      return this.devices.filter(r => r.selected).map(r => r.id)
    },
    ...mapGetters(['hasRole', 'hasSignature'])
  },
  methods: {
    cutString(text) {
      let wordsToCut = 2
      var wordsArray = text.split(" ")
      if (wordsArray.length > wordsToCut) {
        var strShort = ""
        for (let i = 0; i < wordsToCut; i++) {
          strShort += wordsArray[i] + " "
        }
        return strShort + "..."
      } else {
        return text
      }
    },
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    clickedRow(item, index, event) {
      console.log("Clicked!", item)
      window.location = "/report/" + item.id
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      // console.log("data!", this.$refs)
      // window.location = "/report/"+item.id
    },
    getFinalTestResult(tests) {
      // Check if there is a fail in one of the tests WARNING do not test for Pass since there can be retries
      return tests.every(test => { return test.result.toUpperCase() != "FAIL" }) ? "PASS" : "FAIL"
    },
    fetchReports(ctx) {
      console.log("Current_page", ctx)
      this.isBusy = true
      return this.$http.get(`/device`, {params: ctx}).then(response => {
        console.log(response)
        this.devices = response.data.data
        if (this.hasRole('productionOwner')) {
          this.devices = this.devices.map(v => !!v.released_by ? {...v, _rowVariant: 'success'} : v) // eslint-disable-line no-extra-boolean-cast
        }
        this.devices = this.devices.map(v => v.result == 'Fail' ? {...v, _rowVariant: 'warning'} : v)
        this.totalRows = parseInt(response.data.total)
        this.isBusy = false
        return (this.devices)
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        this.isBusy = false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    downloadReport(id, type, name) {
      this.$http.get(`/device/${type}/${id}.pdf`, {
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}_${type}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
    uploadDocument(id) {
      // this.isBusy = true
      const formData = new FormData()

      if (!this.fileList.length) return
      // append the files to FormData
      this.fileList.forEach(x => {
        console.log("Adding document", x)
        formData.append('documents[]', x, x.name)
      })
      console.log("FormData", formData)

      this.$http.post(`/device/${id}/upload`, formData).then((response) => {
        this.$refs.fileinput.reset()
        this.$refs['device-table'].refresh()
      })
    },
    releaseDevice(id) {
      let ids = Array.isArray(id) ? id : [id]
      // this.isBusy = true
      this.$http.put(`device/release`, {
        devices: ids
      }).then(response => {
        this.$refs['device-table'].refresh()
      })
    },
    assignBatchId() {
      this.$http.put(`/device/`, {
        devices: this.devices.filter(r => r.selected).map(r => r.id),
        batch_id: this.batch_id
      }).then(response => {
        console.log(response, this.$refs)
        this.$refs['device-table'].refresh()
        // this.$root.$emit('bv::refresh::table', 'device-table')
        // this.fetchReports()
      })
    }
  },
  created() {
    // this.fetchReports()
    if (this.hasRole('administrator') || this.hasRole('support')) {
      // Add columns based on roles
      this.reportFields.push('report')
      this.reportFields.push('certificate')
    }
    if (this.hasRole('administrator') || this.hasRole('productionOwner')) {
      // Add columns based on roles
      this.reportFields.push('approval')
    }
    this.reportFields.push({key: 'show_details', label: ""})
  }
}
</script>
