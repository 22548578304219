import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import lodashGet from 'lodash/get'

Vue.prototype.$get = lodashGet

Vue.use(VueAxios, axios)
// add these before Vue is instantiated
Vue.axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL
const token = localStorage.getItem('user-token')
if (token) {
  Vue.axios.defaults.headers.common['Authorization'] = token
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
