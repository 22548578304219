import Vue from 'vue'
import Router from 'vue-router'
import ReportList from '@/views/ReportList.vue'
import ReportDetail from '@/views/ReportListDetail.vue'
import DHR from '@/views/DeviceHistoryRecordList.vue'
import LogInForm from '@/components/auth/LogInForm'
import User from '@/views/UserList.vue'
import AccountEdit from '@/views/UserAccountEdit.vue'
import UserNew from '@/views/UserListForm.vue'
import store from '@/store' // your vuex store
import BatchList from '@/views/BatchList.vue'
import CalibrationList from '@/views/CalibrationList.vue'
import FirmwareList from '@/views/FirmwareList.vue'
import Dashboard from '@/views/Dashboard.vue'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  console.log("Auth", store.getters.isAuthenticated)
  if (store.getters.isAuthenticated) {
    console.log("TO: ", to)
    if(to.path == '/'){
      if(store.getters.hasRole('operator')){
        next('/report')
        // next('/dhr')
      }else{
        next('/dashboard')
      }
    }else{
      next()
    }

    // if ((store.getters.hasRole('support') || store.getters.hasRole('productionOwner')) && to.path == '/') {
    //   // Support home is dhr
    //   next('/dhr')
    // } else {
    //   next()
    // }
    return
  }
  next('/login')
}

export default new Router({
  routes: [
    {
      path: '/user',
      name: 'user',
      beforeEnter: ifAuthenticated,
      component: User,
      alias: '/'
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: ifAuthenticated,
      component: Dashboard,
    },
    {
      path: '/firmware',
      name: 'firmware',
      beforeEnter: ifAuthenticated,
      component: FirmwareList
    },
    {
      path: '/user-edit/:id',
      name: 'user-edit',
      component: UserNew,
      beforeEnter: ifAuthenticated,
      props: true
    },
    {
      path: '/user/new',
      name: 'user-new',
      component: UserNew,
      beforeEnter: ifAuthenticated,
      props: true
    },
    {
      path: '/calibration',
      name: 'calibration',
      beforeEnter: ifAuthenticated,
      component: CalibrationList
    },
    {
      path: '/report',
      name: 'report',
      props: true,
      beforeEnter: ifAuthenticated,
      component: ReportList,
    },
    {
      path: '/report-detail/:id',
      name: 'report-detail',
      beforeEnter: ifAuthenticated,
      component: ReportDetail,
      props: true
    },
    {
      path: '/dhr',
      name: 'dhr',
      beforeEnter: ifAuthenticated,
      component: DHR
    },
    {
      path: '/batches',
      name: 'batches',
      beforeEnter: ifAuthenticated,
      component: BatchList
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // },
    {
      path: '/login',
      component: LogInForm,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/account/me',
      component: AccountEdit,
      beforeEnter: ifAuthenticated
    }
  ]
})
