<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Reports list</h2>
      </div>
    </div>
    <div>
      <div class="row justify-content-md-center mb-3">
        <div class="col-1"><label for="filter">Filter</label></div>
        <div class="col-4">
          <b-form-input v-model="filter"
                  type="text"
                  id="filter"
                  placeholder="Search"></b-form-input>
        </div>
        <div class="col-5"></div>
      </div>
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <report-list-table :filter="filter"></report-list-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import { REPORT_REQUEST } from '@/store/actions/reports'
import reportListTable from './ReportListTable'

export default {
  props:['search'],
  data () {
    return {
      name: 'ReportList',
      filter: ""
    }
  },
  components: {
    reportListTable
  },
  computed: {
    ...mapGetters(['hasRole'])
  },
  methods: {},
  created() {
    // this.$store.dispatch(REPORT_REQUEST)
    if(this.search){
      this.filter = this.search
    }
  }
}
</script>
