<template>
  <div id="page-wrap">
    <b-navbar fixed="top" toggleable="md" type="dark" variant="eva-green" :style="cssProps">
      <b-navbar-brand to="/"><b-img height="28" :src="image" class="mb-1 mr-3" /> Portal</b-navbar-brand>
      <b-collapse is-nav id="nav_collapse">
        <!-- LOGGED-IN NAVIGATION -->
        <b-navbar-nav class="ml-auto" >
          <template v-if="isAuthenticated">
            <b-nav-item v-if="!hasRole('operator')" to="/dashboard">Dashboard</b-nav-item>
            <b-nav-item to="/report">Show Reports</b-nav-item>
            <b-nav-item v-if="hasRole('administrator')" to="/user">Manage users</b-nav-item>
            <b-nav-item v-if="hasRole('administrator')" to="/firmware">Manage firmware</b-nav-item>
            <!-- Only in desktop nav -->
            <b-nav-item-dropdown style="text-transform: capitalize;" :text="getProfile('username')" right>
               <b-dropdown-item to="/account/me">Settings</b-dropdown-item>
                <b-dropdown-item @click="logout">Sign out</b-dropdown-item>
            </b-nav-item-dropdown>
          </template>
          <template v-else>
          <!-- LOGGED-OUT NAVIGATION -->
            <b-nav-item to="login">Login</b-nav-item>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view/>
    <footer id="page-footer" class="navbar navbar-light justify-content-between flex-row-reverse">
      <div class="nav">
        <small class="nav-item"><a class="nav-link text-info" href="/contact">Contact<span class="d-none d-sm-inline"> us</span></a></small>
        <small class="nav-item"><a class="nav-link text-info" href="/legal/terms">Terms<span class="d-none d-sm-inline"> of Use</span></a></small>
        <small class="nav-item"><a class="nav-link text-info" href="/legal/privacy">Privacy<span class="d-none d-sm-inline"> policy</span></a></small>
        <template v-if="isAuthenticated">
        <small class="nav-item">
          <a class="nav-link text-info" href="#" @click.prevent.stop="logout">Sign out</a></small>
        </template>
      </div>
      <small class="copy">Copyright &copy; 2021. EVAbits All rights reserved.</small>
    </footer>
  </div>
</template>

<script>
import logo from '@/assets/logo.png'
import { mapGetters } from 'vuex'
import { AUTH_LOGOUT } from '@/store/actions/auth'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)
Vue.use(BootstrapVue)

export default {
  name: 'App',
  data() {
    return {
      image: logo,
      cssProps: {
        backgroundImage: `url(${require('@/assets/bg-bar.jpg')})`,
      }

    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'authStatus', 'hasRole', 'hasSignature', 'getProfile'])
  },
  methods: {
    logout () {
      const { email, password } = this
      this.$store.dispatch(AUTH_LOGOUT, { email, password }).then(() => {
        this.$router.push('/login')
      })
    }
  },
  created: async function () {
    // if (this.$store.getters.isAuthenticated) {
    //   await this.$store.dispatch(USER_REQUEST)
    // }
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        // if you ever get an unauthorized, logout the user
          this.$store.dispatch(AUTH_LOGOUT)
        // you can also redirect to /login if needed !
        }
        throw err
      })
    })
  }
}
</script>


<style lang="scss">
$eva-green:#397d3a;
$eva-grey:#5a6771;
$eva-white:#ffffff;
$body-bg: $eva-white;
$body-color: $eva-grey;
$theme-colors: (
  'eva-green':  $eva-green
);

body {
    padding-top: 70px;
    padding-bottom: 70px;
    margin-bottom: 50px; /* Margin bottom by footer height */
}

html {
  position: relative;
  min-height: 100%;
}

#page-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // width: 100%;
  height: 50px; /* Set the fixed height of the footer here */
  line-height: 25px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.custom-file-label {
  overflow: hidden;
}

@import "~bootstrap/scss/bootstrap";
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>
