<template>
 <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Manage users</h2>
      </div>
    </div>
    <div v-if="users">
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table  :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :striped="true"
                    :hover="true"
                    :items="users"
                    :fields="userFields"
                    :outlined="true"
                    :small="true">
            <template v-slot:cell(selected)="data" label="selected">
                <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected"/>
            </template>
            <template v-slot:cell(username)="data" label="test">
              <router-link :to="{name:'user-edit', params:{id:data.item.id}}">{{data.item.username}}</router-link>
            </template>
            <template v-slot:cell(company)="data" label="test">
              {{data.item.company.name}}
            </template>
            <template v-slot:cell(roles)="data" label="test">
              <ul>
                <li v-bind:key="role.id" v-for="role of data.item.roles">{{role.name}}</li>
              </ul>
            </template>
            <template v-slot:cell(actions)="data" label="test">
              <span class="mr-3">
                <router-link :to="{name:'user-edit', params:{id:data.item.id}}"><octicon name="pencil"></octicon></router-link>
              </span>
              <span>
                <a href="#" v-b-modal="'confirmDelete'+data.item.id"><octicon name="trashcan"></octicon></a>
                  <!-- Modal Component -->
                <b-modal @ok="destroyUser(data.item.id)" :id="'confirmDelete'+data.item.id" title="Are you sure?">
                  <p class="my-4">Do you really want to delete this user?</p>
                </b-modal>
              </span>
            </template>
          </b-table>
          <router-link class="btn btn-primary" to="/user/new"><octicon name="plus"></octicon> Add user</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'

export default {
  name: "UserList",
  components: {
    Octicon
  },
  data() {
    return {
      users: [],
      sortBy: 'end_serial',
      sortDesc: false,
      userFields:
      [
        { key: 'id', label: '#' },
        { key: 'username', sortable: true },
        { key: 'email', sortable: true },
        { key: 'roles', sortable: true },
        { key: 'company', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'actions' }
        // <% if (me.role == "manager") { %>
        // 'approval',
        // <% } %>
        // <% if (me.role == "support" || me.role == "admin") { %>
        // 'download_certificate',
        // 'download_report'
        // <% } %>
      ]
    }
  },
  methods: {
    fetchUsers() {
      this.$http.get(`/api/v1/user`).then(response => {
        console.log(response)
        this.users = response.data
      })
    },
    destroyUser(id) {
      this.$http.delete(`/api/v1/user/${id}`).then(response => {
        console.log(response)
        this.fetchUsers()
      })
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      console.log("data!", this.reports)
      // window.location = "/report/"+item.id
    }
  },
  created() {
    this.fetchUsers()
  }
}
</script>
