<template>
 <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Manage firmware</h2>
      </div>
    </div>
    <div>
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table  :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :striped="true"
                    :hover="true"
                    :items="fetchFirmware"
                    :fields="firmwareFields"
                    :outlined="true"
                    :small="true"
                    ref="firmware-table">
            <template v-slot:cell(remove)="data" label="test">
              <span>
                <a href="#" v-b-modal="'confirmDelete'+data.item.id"><octicon name="trashcan"></octicon></a>
                  <!-- Modal Component -->
                <b-modal @ok="destroyFirmware(data.item.id)" :id="'confirmDelete'+data.item.id" title="Are you sure?">
                  <p class="my-4">Do you really want to remove this firmware?</p>
                </b-modal>
              </span>
            </template>
          </b-table>
          <a class="btn btn-primary" href="#" v-b-modal="'firmwareModal'"><octicon name="plus"></octicon> Upload new firmware</a>
            <b-modal ref="uploadModal" @ok="uploadFirmware" id="firmwareModal" title="Upload firmware" :busy="showUpload" >
              <div v-if="showUpload" class="mb-3">
                Uploading
              </div>
              <div v-else class="mb-3">
                Choose the firmware you want to upload
              </div>
              <b-progress height="2rem" :value="uploadProgress" v-show="showUpload" :max="100" variant="dark"></b-progress>
              <b-form-file v-show="!showUpload" v-model="fileList" ref="fileinput" placeholder="Choose a file..."></b-form-file>
            </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'

export default {
  name: "FirmwareList",
  components: {
    Octicon
  },
  data() {
    return {
      users: [],
      sortBy: 'version',
      sortDesc: false,
      fileList: [],
      firmwareList: [],
      showUpload: false,
      uploadProgress: 0,
      firmwareFields:
      [
        { key: 'id', label: '#' },
        { key: 'version', sortable: true },
        { key: 'description', sortable: true },
        { key: 'type', sortable: true },
        { key: 'remove', sortable: true }
        // <% if (me.role == "manager") { %>
        // 'approval',
        // <% } %>
        // <% if (me.role == "support" || me.role == "admin") { %>
        // 'download_certificate',
        // 'download_report'
        // <% } %>
      ]
    }
  },
  methods: {
    fetchFirmware() {
      return this.$http.get(`/api/v1/firmware`).then(response => {
        console.log(response)
        this.firmwareList = response.data
        return this.firmwareList
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      console.log("data!", this.reports)
      // window.location = "/report/"+item.id
    },
    uploadFirmware(e) {
      e.preventDefault()
      this.showUpload = true
      // this.isBusy = true
      const formData = new FormData()
      // append the files to FormData
      console.log("Adding document", this.fileList)
      formData.append('firmware', this.fileList, this.fileList.name)
      console.log("FormData", formData)

      this.$http.post(`/api/v1/firmware/upload`, formData, {onUploadProgress: (p) => { this.uploadProgress = (p.total / p.loaded) * 100 }}).then((response) => {
        this.fileList = []
        this.showUpload = false
        this.uploadProgress = 0
        this.$refs.uploadModal.hide()
        this.$refs.fileinput.reset()
        this.$refs['firmware-table'].refresh()
      })
    },
    destroyFirmware(id) {
      this.$http.delete(`/api/v1/firmware/${id}`).then(response => {
        console.log(response)
        this.$refs['firmware-table'].refresh()
      })
    }
  },
  created() {
    this.fetchFirmware()
  }
}
</script>
