<template>
  <div class="container">
    <b-row class="row justify-content-md-center">
      
      <!-- <div class="col-10"> -->
        <h1>Dashboard</h1>
      <!-- </div> -->
      <hr class="w-100" />
    </b-row>

    <h3 class="text-center">Batch selection</h3>
      
    <b-row class="justify-content-md-center">
            
        
        <!-- Date picker for "batches done" -->
        <b-col cols="5" >
            <h4>Batches per month</h4>  
    
            <DatePicker :disabled="disable_date_picker" @date-change="date_changed"></DatePicker>
            <b-form-select class="mt-2 mx-auto" :disabled="(selected_date_serials.length == 0 || disable_date_picker)" v-model="selected_serial"  @change="serial_selected" :options="selected_date_serials">
                <template #first>
                    <b-form-select-option :value="null" disabled>-- Please select batch serial --</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col cols="2" offset="2">
            <h4>Latest batch</h4>  
            <b-button :disabled="disable_date_picker" variant="outline-secondary" @click="fetchLatestBatch" >
                Show latest
            </b-button>
        </b-col>

    </b-row>
    <br/>
    <b-row class="justify-content-md-center">
        <b-col class="col-2">
            <h6>Search</h6>  
        </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
        
        <b-col cols="5" >
            <div class="row justify-content-md-center mb-3">
                <div class="col-2"><label class="mt-1" for="filter">Serial:</label></div>
                <div class="col-8">
                <b-form-input v-model="batch_filter"
                         @keypress.enter="search_serial" :disabled="disable_date_picker"
                        type="text"
                        id="filter"
                        placeholder="batch serial"></b-form-input>
                </div>
                <div class="col-2">
                    <b-button size="s" @click.prevent="search_serial" :disabled="disable_date_picker">Search</b-button>
                </div>
                <b-alert class="mt-2"  v-model="show_search_warning_message" variant="warning" dismissible>
                    {{search_warning_message}}
                </b-alert>
            </div>
        </b-col>
    </b-row>

   

    <hr>

    <b-row class="justify-content-md-center mt-5">
        <!-- Table showing latest batch statistics -->
        <b-col cols="4" offset="1">
            <h4>{{ latest_batch_results.title }}</h4>
            <b-table-simple hover bordered>
                <b-tbody>
                    <b-tr>
                        <b-th style="width:170px">Batch serial:</b-th>
                        
                        <b-td colspan="1" class="text-right" v-if="latest_batch_results.batch_serial == -1">
                                No batch found.
                        </b-td>
                        <b-td colspan="1" class="text-right" v-else>
                            <b-link id="batch_serial_report" :to="{name: 'report', params:{ search: latest_batch_results.batch_serial}}">{{ latest_batch_results.batch_serial }} </b-link>

                                <b-tooltip target="batch_serial_report" triggers="hover">
                                Find reports
                            </b-tooltip>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Total devices:</b-th>
                        <b-td colspan="1" class="text-right"> {{ this.latest_batch_results.device_count }} </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Latest produced:</b-th>
                        <b-td colspan="1" class="text-right"> {{ latest_batch_results.latest_date }} </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Passed:</b-th>
                        <b-td variant="success" colspan="1" class="text-right"> {{ latest_batch_results.passed }} </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>Failed:</b-th>
                        <b-td variant="danger" colspan="1" class="text-right"> {{ latest_batch_results.failed }} </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>

        <b-col cols="3">
            <h4>{{ latest_batch_results.title }}</h4>
            <div v-if="chartData">
                <PieChart :chartData="chartData" :options="chartOptions"></PieChart>
            </div>
        </b-col>
    </b-row>
  </div>


</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import moment from 'moment'
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'
import DatePicker from '../components/DatePicker.vue'

import PieChart from '../components/charts/PieChart.js'


Vue.use(BootstrapVue)

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD hh:mm')
  }
})
export default {
  name: 'dashboard',
  components: {
    Octicon,
    DatePicker,
    PieChart,
  },
  data () {
    return {
        search_warning_message: 'Something went wrong.',
        show_search_warning_message: false,
        batch_filter: '',
        chartData: {
            
        },
        chartOptions: {
            responsive: true,
            maintainAspectRatio: true
        },


        disable_date_picker: true,
        selected_serial: null,
        production_date: "",
        selected_date_serials: [],
        selected_month: '',

        latest_batch_results: {
            title: "Latest Batch",
            batch_serial: -1,
            passed: 0,
            failed: 0,
            latest_date: '-',
            device_count: 0
        },

        datacollection:null,
    }
  },
  computed: {
      
    
  },
  methods: {
    search_serial(){
        this.show_search_warning_message= false

        console.log(this.batch_filter);
        this.batch_filter = this.batch_filter.trim()
        if(!this.batch_filter){
            this.show_search_warning_message= true
            this.search_warning_message = 'Please enter a batch serial'
        }else{
            this.serial_selected(this.batch_filter);
        }
    },
    serial_selected(serial){
        this.show_search_warning_message= false

        if(serial){
            this.disable_date_picker = true

            this.$http.get(`/api/v1/dashboard/batchstatistics`, {
                params: {
                    batchserial: serial
                }
            }).then(response => {
                let date = moment(response.data.batch_date).format('YYYY-MM')
                this.apply_results("Selected Batch", response.data.results, date, response.data.batch_serial)
            }).catch((error)=>{
                console.warn(error)
                this.apply_defaults("Selected batch");    
                
                this.show_search_warning_message= true
                if(error && error.response && error.response.status == 400){
                    this.search_warning_message = error.response.data.message
                }

            }).finally(()=>{
                this.disable_date_picker = false
            })
        }
        
    },
    setPieChart(clear){
        if(!clear){
            this.chartData = {
                labels: ["Pass", "Fail"],
                datasets: [
                    {
                    label: 'Batch [id]',
                    backgroundColor: ['#c3e6cb', '#f5c6cb'],
                    data: [this.latest_batch_results.passed, this.latest_batch_results.failed]
                    }
                ]
            }
        }else{
            this.chartData = null
        }
    },
    date_changed(new_date){
        this.show_search_warning_message= false
        this.selected_month = new_date
        this.disable_date_picker = true

        this.batch_filter = '';
        this.chartData = null
        this.disable_date_picker = true

        this.$http.get(`/api/v1/dashboard/monthlybatches`, {
            params: {
                production_date: new_date
            }
        }).then(response => {
            this.selected_serial = null // set dropdown to "please select"
            this.selected_date_serials = response.data
        }).catch((e)=>{
            console.error(e)
            this.selected_serial = null
            this.selected_date_serials = []
            
        }).finally(()=>{
            this.disable_date_picker = false
        })
    },
    fetchLatestBatch() {
        this.show_search_warning_message= false
        this.disable_date_picker = true
        this.$http.get(`/api/v1/dashboard/latestbatch`).then(response => {
            try{
                let date = moment(response.data.batch_date).format('YYYY-MM')
                this.apply_results("Latest Batch", response.data.results, date, response.data.batch_serial)
            }catch(e){
                this.apply_defaults("Latest Batch");    
            }
        }).catch(()=>{
                this.apply_defaults("Latest Batch");
        }).finally(()=>{
            this.selected_serial=null
            this.disable_date_picker = false
        })
    },
    apply_defaults(title){
        this.apply_results(title, {pass: 0, fail:0, total: 0}, '-', '', true)
    },
    apply_results(title, result, date, serial, failed = false){
        this.latest_batch_results.title = title,
        this.latest_batch_results.batch_serial = serial
        this.latest_batch_results.passed = result.pass
        this.latest_batch_results.failed = result.fail 
        this.latest_batch_results.latest_date = date
        this.latest_batch_results.device_count = result.total;
        this.setPieChart(failed)
    }
    
  },
  created() {
  },
  mounted(){
    const date_format = "YYYY-MM-DD"
    var new_date = new Date();
    let date = moment(new_date).format(date_format);
    this.production_date = date

    this.fetchLatestBatch()

    this.disable_date_picker = false
  }
}
</script>
