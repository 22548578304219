<template>
    <b-row class="flex-nowrap mx-0">  
        <b-col cols="2" class="px-1">
            <b-button variant="outline-secondary" @click="previousMonth" align="center"
                class="py-0 px-0"
                style="width: 100%; height:100%"
                :disabled="disabled"

            >
                <octicon name="chevron-left"></octicon>
            </b-button>
        </b-col>
        <b-col cols="8" class="px-0">
            <b-dropdown id="dropdown-form" 
                :disabled="disabled"
                ref="datepickerdropdown" 
                no-caret
                variant="outline-secondary"
                style="min-width: 100%"
                @show="reset_dropdown"
            >
                <template #button-content>
                    <div class="text-left "> 
                        <octicon class="pb-1" name="calendar" scale="1.5"></octicon>
                        {{ getDate() }}
                    </div>
                </template>
                    
                <b-container>
                    <b-container>
                        <b-row class="flex-nowrap">  
                            <b-col class="d-flex justify-content-center">
                                <b-button variant="outline-secondary" size="sm" @click="previousYear" align="center">
                                    <octicon name="chevron-left"></octicon>
                                </b-button>
                            </b-col>
                            <b-col class="d-flex justify-content-center">
                                <h4 class="my-auto">
                                    {{ dropdown_selectedYear }}
                                </h4>
                            </b-col>
                            <b-col class="d-flex justify-content-center">
                                <b-button variant="outline-secondary" size="sm" @click="nextYear" align="center">
                                    <octicon name="chevron-right"></octicon>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-dropdown-divider></b-dropdown-divider>

                        <b-container class="bv-example-row">
                            <b-row style="min-width:200px" >
                                <b-col v-for="(month, index) in months_grid" :key="index" cols="4" class="px-0 py-0" >
                                    <b-button 
                                        squared 
                                        variant='outline-secondary'
                                        :pressed="selectedMonth == index"
                                        style="width:100%; font-size:0.9em; " class="px-0 py-1" 
                                        @click="setMonth(index)" 
                                        >
                                        
                                    {{ month }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-container>
                </b-container>
            </b-dropdown>
        </b-col>
        <b-col cols="2" class="px-1">
            <b-button variant="outline-secondary" @click="nextMonth" align="center"
                :disabled="disabled"
                class="py-0 px-0"
                style="width: 100%; height:100%"
            >
                <octicon name="chevron-right"></octicon>
            </b-button>
        </b-col>
    </b-row>

</template>

<script>

import moment from 'moment'
import Octicon from 'vue-octicon/components/Octicon.vue'

export default {
  name: 'DatePicker',
  components: {
      Octicon
  },
  props: ['disabled'],
  data () {
    return {
        selectedMonth: 0,
        selectedYear: 0,
        dropdown_selectedYear: 0,
        
        months_grid : [
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September','October', 'November', 'December'
        ],
    }
  },
  methods: {
      reset_dropdown(){
          // Reset dropdown
          this.dropdown_selectedYear = this.selectedYear
      },
      nextMonth(){
        this.selectedMonth += 1
        if(this.selectedMonth >= this.months_grid.length){
            this.selectedMonth = 0
            this.nextYear()
        }
        this.dateChanged()

      },
      previousMonth(){
        this.selectedMonth -= 1
        if(this.selectedMonth < 0){
            this.selectedMonth = 11
            this.previousYear()
        }
        this.dateChanged()
      },
      dateChanged(){
        this.selectedYear = this.dropdown_selectedYear
        this.$emit('date-change', `${this.selectedYear}-${this.selectedMonth+1}`)
      },
      setMonth(month){
        this.$refs.datepickerdropdown.hide(true)
        this.selectedMonth = month
        this.dateChanged()
      },
      getDate(){
        return `${this.selectedYear} ${this.months_grid[this.selectedMonth]} | ${this.selectedMonth + 1} ${this.selectedYear}`
      },
      previousYear(){
        if(this.dropdown_selectedYear > 1){
            this.dropdown_selectedYear -= 1
        }
      },
      nextYear(){
        this.dropdown_selectedYear += 1

        if(this.dropdown_selectedYear < 0){
            this.dropdown_selectedYear = 0 
        }
        
      }
  },
  mounted () {
      this.dropdown_selectedYear = moment().year()
      this.selectedYear = this.dropdown_selectedYear
      this.selectedMonth = moment().month()

      this.dateChanged()
  }
}

</script>


