<template>
 <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <div v-if="user" class="row  justify-content-md-center">
      <div class="col-10">
        <b-alert :show="dismissCountDown"
             dismissible
             :variant="notification.type"
             @dismissed="dismissCountDown=0"
             @dismiss-count-down="countDownChanged">
             {{ notification.message }}
        </b-alert>
        <form class="ui form" @submit.prevent.stop>
          <div class="form-group">
            <label for="username">Username</label>
            <input type="input" name="username" v-model.trim="$v.user.username.$model" :class="{ 'form-control': true, 'is-valid': !$v.user.username.$invalid, 'is-invalid': $v.user.username.$invalid && $v.user.username.$dirty }" aria-describedby="usernameHelp" placeholder="Enter username" required>
            <b-form-invalid-feedback>
              Please enter a username of at least 2 chars
            </b-form-invalid-feedback>
          </div>
          <div class="form-group">
            <label for="email">Email address</label>
            <input type="email" name="email" v-model.trim="$v.user.email.$model" :class="{ 'form-control': true, 'is-valid': !$v.user.email.$invalid, 'is-invalid': $v.user.email.$invalid && $v.user.email.$dirty }" aria-describedby="emailHelp" placeholder="Enter email" required>
            <b-form-invalid-feedback>
              Please enter a valid email address
            </b-form-invalid-feedback>
          </div>
          <div class="form-group">
            <label>Roles (You can select multiple)</label>
            <b-form-select multiple :select-size="4" v-model="$v.user.selected_roles.$model" :options="roles" :class="{ 'mb-3': true, 'is-valid': !$v.user.selected_roles.$invalid, 'is-invalid': $v.user.selected_roles.$invalid && $v.user.selected_roles.$dirty }">
            </b-form-select>
            <b-form-invalid-feedback>
              Please select at least one role
            </b-form-invalid-feedback>
          </div>
          <div class="form-group">
            <label>Company</label>
            <b-form-select :class="{ 'mb-3': true, 'is-valid': !$v.user.selected_company.$invalid, 'is-invalid': $v.user.selected_company.$invalid && $v.user.selected_company.$dirty }" v-model="$v.user.selected_company.$model" :options="companies">
            </b-form-select>
            <b-form-invalid-feedback>
              Please select a company
            </b-form-invalid-feedback>
          </div>
          <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" name="password" v-model.trim="user.password" :class="{ 'mb-3': true }" placeholder="Password">
            <b-form-invalid-feedback>
              Password must be at least 6 characters
            </b-form-invalid-feedback>
          </div>
          <div class="form-group">
            <label>Repeat password</label>
            <input type="password" class="form-control" name="passwordRepeat" v-model.trim="user.passwordRepeat" :class="{ 'mb-3': true }" placeholder="Repeat password">
            <b-form-invalid-feedback>
              Password repeat must be the same as password
            </b-form-invalid-feedback>
          </div>
          <button @click.prevent.stop="submit" type="submit" class="btn btn-primary">{{ buttonText }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  name: "UserListForm",
  components: {
    Octicon
  },
  data() {
    return {
      user: {
        username: "",
        password: null,
        passwordRepeat: "",
        email: "",
        roles: [],
        tokens: [],
        company: [],
        selected_roles: [],
        selected_company: []
      },
      user_roles: [],
      roles: [],
      companies: [],
      dismissCountDown: 0,
      dismissSecs: 8,
      notification: {
        message: '',
        type: ''
      },
      buttonText: "Create User",
      title: "New user",
      submitAction: null,
      mode: "create"
    }
  },
  validations() {
    return {
      user: {
        username: {
          required,
          minLength: minLength(2)
        },
        email: {
          email,
          required
        },
        selected_roles: {
          required
        },
        selected_company: {
          required
        }
      }
    }
  },
  methods: {
    fetchUser(id) {
      this.$http.get(`/api/v1/user/${id}`).then(response => {
        console.log("fetch user:", response)
        this.user = response.data
        this.user.password = null
        this.user.selected_roles = this.user.roles.map((r) => r.id)
        this.user.selected_company = this.user.company_id // .map((r) => r.id)
      })
    },
    fetchRoles() {
      this.$http.get(`/api/v1/roles`).then(response => {
        console.log(response)
        this.roles = response.data.map((r) => { return {value: r.id, text: r.name} })
      })
    },
    fetchCompanies() {
      this.$http.get(`/api/v1/companies`).then(response => {
        console.log(response)
        this.companies = response.data.map((r) => { return {value: r.id, text: r.name} })
      })
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    submit() {
      // Update selected roles
      this.user.company_id = this.user.selected_company
      this.user.roles = this.user.selected_roles
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitAction()
      }
    },
    createUser() {
      this.$http.post(`/api/v1/user`, this.user).then(response => {
        console.log(response)
        this.$router.go(-1)
      }).catch((err, data) => {
        console.log(err.response)
        this.notification = {
          message: err.response.data.message,
          type: 'danger'
        }
        this.showAlert()
      })
    },
    updateUser() {
      this.$http.put(`/api/v1/user/${this.user.id}`, this.user).then(response => {
        console.log(response)
        this.$router.go(-1)
      }).catch((err, data) => {
        console.log(err.response)
        this.notification = {
          message: err.response.data.message,
          type: 'danger'
        }
        this.showAlert()
      })
    }
  },
  created() {
    console.log(this.$route.params.id)
    if (this.$route.params.id) {
      this.mode = "edit"
      console.log("EDIT mode")
      this.fetchUser(this.$route.params.id)
      this.title = "Edit user"
      this.buttonText = "Update user"
      this.submitAction = this.updateUser
    } else {
      this.mode = "create"
      this.submitAction = this.createUser
    }
    this.fetchRoles()
    this.fetchCompanies()
  }
}
</script>
