<template>
  <div>
    <b-table        
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy.sync="isBusy"
        :striped="true"
        :hover="true"
        :items="fetchReports"
        :fields="reportFields"
        :outlined="true"
        :small="true"
        sort-icon-left
        :filter="filter"
        :current-page="currentPage"
        :per-page="perPage">
      <template v-slot:cell(selected)="data">
        <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected" />
      </template>
      <template v-slot:cell(index)="data" label="test">
        {{data.item.id}}
      </template>
        <template v-slot:cell(target_data)="data" label="test">
          <router-link :to="{name:'report-detail', params: {id:data.item.id} }" >
          {{formatTargetData(data.item.target_data, 'value')}}
         </router-link>
      </template>
      <template v-slot:cell(target_imei)="data" label="test">
        {{formatTargetData(data.item.target_data, 'imei')}}
      </template>
      <template v-slot:cell(operator)="data" label="test3">
        {{ data.item.user.username }}
      </template>
      <!--
      <template slot="selected" slot-scope="data" label="selected">
        <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected" />
      </template>
      
      <template slot="index" slot-scope="data" label="test">
        {{data.item.id}}
      </template>
      
      <template slot="end_serial" slot-scope="data" label="test">
        <router-link :to="{name:'report-detail', params:{id:data.item.id}}">{{data.item.end_serial}}</router-link>
      </template>
      -->
      <!--
      <template slot="production_date" slot-scope="data" label="test">
        {{data.item.production_date | formatDate}}
      </template>
      
      <template slot="operator" slot-scope="data" label="test">
        {{ $get(data, 'item.user.username') }}
      </template>
      -->
    </b-table>
    <div v-if="!device_id" class="row justify-content-md-center">
      <div class="col-6 mt-3">
        <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import moment from 'moment'
import { mapGetters } from 'vuex'
Vue.use(BootstrapVue)

export default {
  props: [
    'device_id',
    'filter'
  ],
  data () {
    return {
      name: 'report-list-table',
      sortBy: 'id',
      sortDesc: true,
      graphLoaded: false,
      currentPage: 1,
      perPage: 20,
      isBusy: false,
      totalRows: 0,
      // reports: [],
      reportFields:
      [
        { key: 'selected', label: 's' },
        { key: 'id', label: '#', sortable: true },
        { key: 'serial', sortable: true },
        { key: 'target_data', sortable: true, label:"Target Serial" },
        { key: 'target_imei', sortable: true, label:"Target IMEI" },
        { key: 'operator', sortable: true },
        { key: 'test_type', sortable: true },
        { key: 'revision', sortable: true },
        { key: 'production_date', sortable: true, formatter: (value, key, item) => { 
            return moment(String(value)).format('YYYY-MM-DD hh:mm') 
            }
        },
        { key: 'duration', sortable: true },
        { key: 'version', sortable: true },
        { key: 'result', sortable: true }
        // (() => {return this.$store.getters.hasRole('manager') || this.$store.getters.hasRole('administrator') ? 'approval' : null})()
        // <% if (me.role == "manager") { %>
        // 'approval',
        // <% } %>
        // <% if (me.role == "support" || me.role == "admin") { %>
        // 'download_certificate',
        // 'download_report'
        // <% } %>
      ]
    }
  },
  computed: {
    ...mapGetters(['hasRole'])
  },
  methods: {
   formatTargetData(data, field) {
      if (data) {
        try{
          let res = data.filter(f => f[field] != '' && f.selected).map(f => f[field]).join(", ").substring(0, 20)+"..."
          return res
        }catch(e){
          //console.error(e)
        }

        return data[field]
      }
    },
    fetchReports(ctx) {
      console.log("Current_page", ctx)
      this.isBusy = true
      return this.$http.get(`api/v1/report`, {
        params: {
          ...ctx,
          device_id: this.device_id
        }
      }).then(response => {
        console.log(response)
        this.devices = response.data.data
        if (this.hasRole('productionOwner')) {
          this.devices = this.devices.map(v => !!v.released_by ? {...v, _rowVariant: 'success'} : v) // eslint-disable-line no-extra-boolean-cast
        }
        this.devices = this.devices.map(v => v.result == 'Fail' ? {...v, _rowVariant: 'warning'} : v)
        this.totalRows = parseInt(response.data.total)
        this.isBusy = false
        return (this.devices)
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        this.isBusy = false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      console.log("data!", this.reports)
      // window.location = "/report/"+item.id
    }
  }
}
</script>
