<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Batches</h2>
      </div>
    </div>
    <div v-if="batches">
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table  :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :striped="true"
                    :hover="true"
                    :items="batches"
                    :fields="reportFields"
                    :outlined="true"
                    :small="true">
            <template slot="selected" slot-scope="data" label="selected">
                <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected">
                  </b-form-checkbox>
              </template>
            <template slot="index" slot-scope="data" label="test">
              {{data.index}}
            </template>
            <template slot="number_of_devices" slot-scope="data" label="test">
              {{ data.item.__meta__.devices_count }}
            </template>
            <template slot="batch_id" slot-scope="data" label="result">
              {{data.item.batch_id || "Not assigned"}}
            </template>
            <template slot="released_by" slot-scope="data" label="result">
              {{data.item.username}}
            </template>
            <template slot="show_details" slot-scope="row">
              <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
              <b-button size="sm" @click.stop="row.toggleDetails" class="mr-2">
              {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
              </b-button>
            </template>
            <template slot="row-details" slot-scope="row">

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-left"><b>Devices in this batch:</b></b-col>
                </b-row>
                <b-row v-bind:key="device.id" v-for="device in row.item.devices" class="mb-2">
                  <b-col sm="3" class="text-sm-right">{{ device.end_serial }}</b-col>
                </b-row>

            </template>
          </b-table>
            <b-button :disabled="!selected_items" size="sm" @click.stop="downloadBatch()" class="mr-2">
              Download SAP Export file
              </b-button>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-6 mt-3">
        <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import moment from 'moment'
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'

Vue.use(BootstrapVue)

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD hh:mm')
  }
})
export default {
  name: 'batch-list',
  components: {
    Octicon
  },
  data () {
    return {
      sortBy: 'id',
      sortDesc: true,
      isBusy: false,
      currentPage: 1,
      perPage: 15,
      filter: "",
      totalRows: 0,
      graphLoaded: false,
      batches: [],
      batch_id: null,
      fileList: [],
      reportFields:
      [
        { key: 'selected', label: '' },
        { key: 'id', label: '#' },
        { key: 'name', sortable: true },
        { key: 'number_of_devices', sortable: true },
        { key: 'show_details', sortable: true }
      ]
    }
  },
  computed: {
    selected_items() {
      return this.batches.some(r => r.selected)
    }
  },
  methods: {
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    clickedRow(item, index, event) {
      console.log("Clicked!", item)
      window.location = "/report/" + item.id
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      console.log("data!", this.devices)
      // window.location = "/report/"+item.id
    },
    getFinalTestResult(tests) {
      // Check if there is a fail in one of the tests WARNING do not test for Pass since there can be retries
      return tests.every(test => { return test.result.toUpperCase() != "FAIL" }) ? "PASS" : "FAIL"
    },
    fetchBatches(ctx) {
      console.log("Current_page", ctx)
      this.isBusy = true
      return this.$http.get(`/batches`, {params: ctx}).then(response => {
        console.log(response)
        this.batches = response.data.data
        this.totalRows = parseInt(response.data.total)
        this.isBusy = false
        return (this.batches)
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        this.isBusy = false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    downloadBatch() {
      this.$http.get(`/sap`, {
        params: {
          devices: this.batches.filter(r => r.selected).map(r => r.id)
        }
      }).then(response => {
        console.log(response.data)
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data, null, 2)]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `EXPORT_${moment().format("YYYYMMDD_HHmm")}_BATCH_RESULTS.json`) // or any other extension
        document.body.appendChild(link)
        link.click()
        // this.batches = response.data
      })
    }
  },
  created() {
    this.fetchBatches()
  }
}
</script>
