/* eslint-disable promise/param-names */
import { USER_SUCCESS, AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT } from '../actions/auth'
import Vue from 'vue'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  hasLoadedOnce: false,
  profile: JSON.parse(localStorage.getItem('user')) || {}
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  hasRole: state => role => !!state.profile.roles && state.profile.roles.some(r => r.slug == role),
  getProfile: state => key => !!state.profile && state.profile[key],
  hasSignature: state => !!state.profile.signature
}

const actions = {
  [AUTH_REQUEST]: ({commit, dispatch}, user) => {
    commit(AUTH_REQUEST)
    return Vue.axios.post('/api/v1/login', {
      email: user.email,
      password: user.password
    }).then(response => {
      // save token in localstorage
      const token = `Bearer ${response.data.data.token}`
      localStorage.setItem('user-token', token)
      Vue.axios.defaults.headers.common['Authorization'] = token
      commit(AUTH_SUCCESS, token)
      return Vue.axios.get('/api/v1/account/me')
    }).then(response => {
      console.log("user response", response)
      localStorage.setItem('user', JSON.stringify(response.data.data))
      commit(USER_SUCCESS, response.data.data)
      return response.data
    }).catch(err => {
      // clear form inputs
      console.log(err)
      commit(AUTH_ERROR, err)
      localStorage.removeItem('user-token')
      localStorage.removeItem('user')
      throw err
    })
  },
  [AUTH_LOGOUT]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT)
      localStorage.removeItem('user-token')
      localStorage.removeItem('user')
      resolve()
    })
  }
}

const mutations = {
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'profile', resp)
  },
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.token = resp
    state.hasLoadedOnce = true
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
