<template>
  <div id="elementId" ref="geoChart" class="chart-root"></div>
</template>

<script>
/* eslint-disable no-unused-vars */
import c3 from 'c3'

export default {
  props: ['elementId', 'title', 'values', 'labels', 'leftBorder', 'rightBorder'],
  computed: {
    yValues() {
      let data = Array.from(this.values)
      data.unshift(this.title)
      return data
    },
    xValues() {
      let data = Array.from(this.labels)
      data.unshift('x')
      return data
    }
  },
  mounted: function() {
    let chart = c3.generate({
      size: {
        height: 400,
        width: 600
      },
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 50
      },
      bindto: this.$refs.geoChart,
      data: {
        x: 'x',
        columns: [
          this.xValues,
          this.yValues
        ]
      },
      axis: {
        x: {
          tick: {
            format: (x) => {
              return +(Math.round(x + "e+1") + "e-1")
            }
          }
        }
      },
      grid: {
        y: {
          show: true
        },
        x: {
          lines: [
            {value: this.leftBorder},
            {value: this.rightBorder}
          ]
        }
      },
      regions: [
        {start: this.leftBorder, end: this.rightBorder}
      ]
    })
  }
}
/* eslint-enable no-unused-vars */
</script>
<style lang="scss">
@import '~c3/c3.css';

.c3 .c3-axis path, .c3 .c3-axis line {
    stroke: #6C757D;
}
.c3-line{
    stroke-width:5px
}
.c3-axis-y text {
    fill: #6C757D;
    font-size:13px;
}
.c3-axis-x text {
    fill:#6C757D;
    font-size:13px;
}

</style>
