<template>
 <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Edit your account settings</h2>
      </div>
    </div>
    <div v-if="user" class="row  justify-content-md-center">
      <div class="col-10">
        <b-alert 
            :show="dismissCountDown"
             dismissible
             :variant="notification.type"
             @dismissed="dismissCountDown=0"
             @dismiss-count-down="countDownChanged">
             {{ notification.message }}
        </b-alert>
        <form class="ui form" @submit.prevent.stop>
          <div class="form-group">
            <label for="username">Username</label>
            <input readonly type="input" name="username" v-model="user.username" class="form-control" aria-describedby="usernameHelp" placeholder="Enter username" required>
          </div>
          <div class="form-group">
            <label for="email">Email address</label>
            <input readonly type="email" name="email" v-model="user.email" class="form-control" aria-describedby="emailHelp" placeholder="Enter email" required>
          </div>
          <div class="form-group">
            <label>Company: </label>
            {{ user.company.name }}
          </div>
          <div class="form-group">
            <label for="api_token">API Token</label>
             <!-- <b-table 
                    :striped="true"
                    :hover="true"
                    :items="fetchTokens"
                    :fields="tokenFields"
                    :outlined="true"
                    :small="true"
                    ref="token-table">
            <template v-slot:cell(revoke)="data">
              <span>
                <a href="#" v-b-modal="'confirmDelete'+data.item.id"><octicon name="trashcan"></octicon></a>
               
                <b-modal @ok="revokeToken(data.item.id)" :id="'confirmDelete'+data.item.id" title="Are you sure?">
                  <p class="my-4">Do you really want to remove this firmware?</p>
                </b-modal>
              </span>
            </template>
          </b-table> -->
            <!-- <ul>
              <li v-bind:key="token.id" v-for="token in fetchTokens()">Token: {{ token.token }} Revoked: {{ token.is_revoked }}</li>
            </ul> -->
            <b-input-group class="mt-3">
              <b-form-input class="form-control" name="api_token" v-model="user.api_token" placeholder="Press generate to generate a token"></b-form-input>
              <b-input-group-append>
                <b-button v-b-modal="'confirmRegenerate'" >Generate token</b-button>
                <b-modal @ok="generateToken()" id="confirmRegenerate" title="Are you sure?">
                  <p class="my-4">Do you really want to regenerate this token?<br>This will invalidate the old one</p>
                </b-modal>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" name="password" v-model="user.password" placeholder="Password">
          </div>
          <div class="form-group">
            <label>Repeat password</label>
            <input type="password" class="form-control" name="passwordRepeat" v-model="user.passwordRepeat" placeholder="Repeat password">
          </div>

           <b-btn @click.prevent.stop="updateUser" variant="primary">Save</b-btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'
import {USER_SUCCESS} from '@/store/actions/user'

export default {
  name: "UserAccountEdit",
  components: {
    Octicon
  },
  data() {
    return {
      user: {
        password: "",
        roles: [],
        api_token: "",
        company: {
          name: ""
        },
        tokens: []
      },
      signatureFile: {
        name: ""
      },
      tokenFields: [
        { key: "token", label: "Token" },
        { key: "revoke", label: "Revoke" }
      ],
      dismissCountDown: 0,
      countDownChanged: 0,
      dismissSecs: 8,
      notification: {
        message: '',
        type: ''
      },
      user_roles: [],
      roles: [],
      selected_roles: [],
      companies: [],
      selected_company: []
    }
  },
  methods: {
    fetchUser() {
      this.$http.get(`/api/v1/account/me`).then(response => {
        console.log(response)
        this.user = response.data.data
        this.selected_roles = this.user.roles.map((r) => r.id)
        this.selected_company = this.user.company_id // .map((r) => r.id)
        localStorage.setItem('user', JSON.stringify(response.data.data))
        this.$store.commit(USER_SUCCESS, response.data.data)
      })
    },
    fetchTokens() {
      return this.$http.get(`/api/v1/account/me/tokens`).then(response => {
        console.log("tokens", response.data)
        return response.data.data
      }).catch(() => {
        return []
      })
    },
    revokeToken() {
      
    },
    fetchRoles() {
      this.$http.get(`/api/v1/roles`).then(response => {
        console.log(response)
        this.roles = response.data.map((r) => { return {value: r.id, text: r.name} })
      })
    },
    fetchCompanies() {
      this.$http.get(`/api/v1/companies`).then(response => {
        console.log(response)
        this.companies = response.data.map((r) => { return {value: r.id, text: r.name} })
      })
    },
    generateToken() {
      this.$http.put(`/api/v1/account/me/token`).then((response) => {
        this.fetchUser()
      })
    },
    uploadSignature() {
      const formData = new FormData()

      if (!this.signatureFile) return
      // append the files to FormData
      console.log("Adding signature", this.signatureFile)
      formData.append('signature', this.signatureFile, this.signatureFile.name)
      console.log("FormData", formData)

      this.$http.post(`/api/v1/account/me/signature`, formData).then((response) => {
        this.$refs.fileinput.reset()
        this.signatureFile = {
          name: ""
        }
        this.fetchUser()
      })
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    updateUser() {
      this.$http.put(`/api/v1/account/me`, this.user).then(response => {
        console.log(response)
        this.$router.go(-1)
      }).catch((err, data) => {
        console.log(err.response)
        this.notification = {
          message: err.response.data.message,
          type: 'danger'
        }
        this.showAlert()
      })
    }
  },
  created() {
    this.fetchUser()
    // this.fetchRoles()
    // this.fetchCompanies()
  }
}
</script>
